import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { isEmpty, pickBy } from "lodash";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { notNullOrUndefined } from "../../../../util/notNullOrUndefined";
import { stringIsEmpty } from "../../../../util/stringIsEmpty";
import { trimmedOrUndefined } from "../../../../util/trimmedOrUndefined";

import {
  SiteDetailsQuery,
  SwiftConnectHidSdk,
  usePatchSwiftHidsdkMutation,
} from "../../../../generated/admin";

interface HIDSDK {
  applicationId: string;
  hidOrigoId: string;
}

interface Props {
  label?: string;
  applicationId: string;
  hidOrigoId: string;
  dest: NonNullable<SiteDetailsQuery>["destination"];
  successCb: (
    email: string,
    connectedTs: number,
    sdk: HIDSDK,
    label: string
  ) => void;
}

interface FormValue<T = string> {
  invalid: boolean;
  value: T;
}

interface ConnectForm {
  label: FormValue;
  applicationId: FormValue;
  hidOrigoId: FormValue;
}

export const SwiftconnectConnectFormEditHid: React.FC<Props> = ({
  dest,
  successCb,
  label = "",
  applicationId,
  hidOrigoId,
}) => {
  const [patchMutation, { loading }] = usePatchSwiftHidsdkMutation();
  const [state, setState] = useState<ConnectForm>({
    label: { invalid: false, value: label },
    applicationId: { invalid: false, value: applicationId },
    hidOrigoId: { invalid: false, value: hidOrigoId },
  });

  const artwork = dest.integrations?.swiftconnect.artWork;

  const updateConnection = useCallback(async () => {
    try {
      if (stringIsEmpty(artwork)) {
        throw new Error(
          "The Access pass artwork is missing. Please add this file and save the form then try again."
        );
      }

      if (
        loading ||
        state.label.invalid ||
        state.applicationId.invalid ||
        state.hidOrigoId.invalid
      ) {
        return;
      }

      // filter changed non-empty fields
      const changed = pickBy(
        {
          label:
            state.label.value.trim() !== label
              ? trimmedOrUndefined(state.label.value)
              : undefined,
          applicationId:
            state.applicationId.value.trim() !== applicationId
              ? trimmedOrUndefined(state.applicationId.value)
              : undefined,
          hidOrigoId:
            state.hidOrigoId.value.trim() !== hidOrigoId
              ? trimmedOrUndefined(state.hidOrigoId.value)
              : undefined,
        },
        notNullOrUndefined
      );
      if (isEmpty(changed)) {
        return;
      }

      const result = await patchMutation({
        variables: { site: dest.uuid, ...changed },
      });

      if (result.data?.swiftconnect?.patchHIDSDK == null) {
        throw new Error("Connection returning no data.");
      }

      if (
        result.data.swiftconnect.patchHIDSDK.__typename ===
        "SwiftconnectAuthenticationFailure"
      ) {
        throw new Error(result.data.swiftconnect.patchHIDSDK.reason);
      }

      const info = result.data.swiftconnect.patchHIDSDK.info;
      EqMessageSuccess({ text: `Successfully updated ${info.label}.` });
      successCb(
        info.email,
        info.connectedTimestamp,
        info.sdk as SwiftConnectHidSdk,
        info.label
      );
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [
    state,
    label,
    applicationId,
    hidOrigoId,
    loading,
    artwork,
    patchMutation,
    dest.uuid,
    successCb,
  ]);

  const applicationIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        applicationId: { invalid: stringIsEmpty(value), value },
      }));
    },
    []
  );

  const origoIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        hidOrigoId: { invalid: stringIsEmpty(value), value: value },
      }));
    },
    []
  );

  return (
    <div>
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Label
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectLabel"
            type="text"
            isInvalid={state.label.invalid}
            disabled={loading}
            onChange={(e) =>
              setState((connectForm) => ({
                ...connectForm,
                label: {
                  value: e.target.value,
                  invalid: e.target.value.length > 20,
                },
              }))
            }
            defaultValue={state.label.value}
          />
          <Form.Text className="text-muted">
            Integration name used as label in the mobile app (up to 20
            characters long)
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Label must be 20 characters or less
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Application ID
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectApplicationId"
            type="text"
            isInvalid={state.applicationId.invalid}
            disabled={loading}
            onChange={applicationIdOnChange}
            defaultValue={state.applicationId.value}
          />
          <Form.Text className="text-muted">
            The format will be similar to HID-SWIFTCONNECT-TEST
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          HID Origo UUID
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectOrigoId"
            type="text"
            isInvalid={state.hidOrigoId.invalid}
            disabled={loading}
            onChange={origoIdOnChange}
            defaultValue={state.hidOrigoId.value}
          />
          <Form.Text className="text-muted">
            The format will be similar to 098d22af-2b67-411c-8a92-29269b69fda3
          </Form.Text>
        </Col>
      </Form.Group>
      <div className="text-right">
        <Button
          name="connectSwiftconnect"
          variant="outline-primary"
          onClick={updateConnection}
          disabled={loading || state.label.invalid}
        >
          {loading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Update SwiftConnect Connection"
          )}
        </Button>
      </div>
    </div>
  );
};
