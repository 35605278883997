import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { isEmpty, pickBy } from "lodash";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { notNullOrUndefined } from "../../../../util/notNullOrUndefined";
import { stringIsEmpty } from "../../../../util/stringIsEmpty";
import { trimmedOrUndefined } from "../../../../util/trimmedOrUndefined";

import {
  SiteDetailsQuery,
  SwiftConnectMobileCredentialsSdk,
  usePatchSwiftMobileCredentialsSdkMutation,
} from "../../../../generated/admin";

interface MobileSDK {
  instanceId: string;
  organizationId: string;
}

interface Props {
  label?: string;
  instanceId: string;
  organizationId: string;
  dest: NonNullable<SiteDetailsQuery>["destination"];
  successCb: (
    email: string,
    connectedTs: number,
    sdk: MobileSDK,
    label: string
  ) => void;
}

interface FormValue<T = string> {
  invalid: boolean;
  value: T;
}

interface ConnectForm {
  label: FormValue;
  instanceId: FormValue;
  organizationId: FormValue;
}

export const SwiftconnectConnectFormEditMobileCredential: React.FC<Props> = ({
  dest,
  successCb,
  label = "",
  instanceId,
  organizationId,
}) => {
  const [patchMutation, { loading }] =
    usePatchSwiftMobileCredentialsSdkMutation();
  const [state, setState] = useState<ConnectForm>({
    label: { invalid: false, value: label },
    instanceId: { invalid: false, value: instanceId },
    organizationId: { invalid: false, value: organizationId },
  });

  const artwork = dest.integrations?.swiftconnect.artWork;

  const updateConnection = useCallback(async () => {
    try {
      if (stringIsEmpty(artwork)) {
        throw new Error(
          "The Access pass artwork is missing. Please add this file and save the form then try again."
        );
      }

      if (
        loading ||
        state.label.invalid ||
        state.instanceId.invalid ||
        state.organizationId.invalid
      ) {
        return;
      }

      // filter changed non-empty fields
      const changed = pickBy(
        {
          label:
            state.label.value.trim() !== label
              ? trimmedOrUndefined(state.label.value)
              : undefined,
          instanceId:
            state.instanceId.value.trim() !== instanceId
              ? trimmedOrUndefined(state.instanceId.value)
              : undefined,
          organizationId:
            state.organizationId.value.trim() !== organizationId
              ? trimmedOrUndefined(state.organizationId.value)
              : undefined,
        },
        notNullOrUndefined
      );
      if (isEmpty(changed)) {
        return;
      }

      const result = await patchMutation({
        variables: { site: dest.uuid, ...changed },
      });

      if (result.data?.swiftconnect?.patchMobileCredentialsSDK == null) {
        throw new Error("Connection returning no data.");
      }

      if (
        result.data.swiftconnect.patchMobileCredentialsSDK.__typename ===
        "SwiftconnectAuthenticationFailure"
      ) {
        throw new Error(
          result.data.swiftconnect.patchMobileCredentialsSDK.reason
        );
      }

      const info = result.data.swiftconnect.patchMobileCredentialsSDK.info;
      EqMessageSuccess({ text: `Successfully updated ${info.label}.` });
      successCb(
        info.email,
        info.connectedTimestamp,
        info.sdk as SwiftConnectMobileCredentialsSdk,
        info.label
      );
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [
    state,
    label,
    instanceId,
    organizationId,
    loading,
    artwork,
    patchMutation,
    dest.uuid,
    successCb,
  ]);

  const instanceIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        instanceId: { invalid: stringIsEmpty(value), value },
      }));
    },
    []
  );

  const organizationIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        organizationId: { invalid: stringIsEmpty(value), value: value },
      }));
    },
    []
  );

  return (
    <div>
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Label
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectLabel"
            type="text"
            isInvalid={state.label.invalid}
            disabled={loading}
            onChange={(e) =>
              setState((connectForm) => ({
                ...connectForm,
                label: {
                  value: e.target.value,
                  invalid: e.target.value.length > 20,
                },
              }))
            }
            defaultValue={state.label.value}
          />
          <Form.Text className="text-muted">
            Integration name used as label in the mobile app (up to 20
            characters long)
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Label must be 20 characters or less
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Instance UUID
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectInstanceId"
            type="text"
            isInvalid={state.instanceId.invalid}
            disabled={loading}
            onChange={instanceIdOnChange}
            defaultValue={state.instanceId.value}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Organization UUID
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectOrganizationId"
            type="text"
            isInvalid={state.organizationId.invalid}
            disabled={loading}
            onChange={organizationIdOnChange}
            defaultValue={state.organizationId.value}
          />
        </Col>
      </Form.Group>
      <div className="text-right">
        <Button
          name="connectSwiftconnect"
          variant="outline-primary"
          onClick={updateConnection}
          disabled={loading || state.label.invalid}
        >
          {loading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Update SwiftConnect Connection"
          )}
        </Button>
      </div>
    </div>
  );
};
