import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";

import { isEmpty, pickBy } from "lodash";
import { usePatchGallagherMutation } from "../../../../generated/admin";
import { trimmedOrUndefined } from "../../../../util/trimmedOrUndefined";
import { notNullOrUndefined } from "../../../../util/notNullOrUndefined";

interface Props {
  label: string;
  useIntegrationLicense: boolean;
  isLicensed?: boolean;
  siteUuid: string;
}

interface FormValue {
  invalid: boolean;
  value: string;
}

interface PatchForm {
  label: FormValue;
  useIntegrationLicense: boolean;
}

export const GallagherConnectFormEdit: React.FC<Props> = ({
  label,
  useIntegrationLicense,
  isLicensed,
  siteUuid,
}) => {
  const [mutation, { loading }] = usePatchGallagherMutation({
    refetchQueries: ["SiteDetails"],
  });
  const [state, setState] = useState<PatchForm>({
    useIntegrationLicense,
    label: { invalid: false, value: label },
  });

  const patch = useCallback(async () => {
    try {
      if (state.label.invalid || loading) {
        return;
      }

      // filter changed non-empty fields
      const changed = pickBy(
        {
          label:
            state.label.value.trim() === label
              ? undefined
              : trimmedOrUndefined(state.label.value),
          useIntegrationLicense:
            state.useIntegrationLicense === useIntegrationLicense
              ? undefined
              : state.useIntegrationLicense,
        },
        notNullOrUndefined
      );
      // no changes ¯\_(ツ)_/¯
      if (isEmpty(changed)) {
        return;
      }

      const result = await mutation({
        variables: { site: siteUuid, ...changed },
      });

      if (null == result.data?.gallagher?.patch) {
        throw new Error("Connection returning no data.");
      }

      if (
        "GallagherAuthenticationFailure" ===
        result.data.gallagher.patch.__typename
      ) {
        throw new Error(result.data.gallagher.patch.reason);
      }

      EqMessageSuccess({
        text: `Successfully updated ${result.data.gallagher.patch.info.label}.`,
      });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [state, loading, mutation, label, useIntegrationLicense, siteUuid]);

  return (
    <div>
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Label
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="gallagherLabel"
            type="text"
            isInvalid={state.label.invalid}
            disabled={loading}
            onChange={(e) =>
              setState((connectForm) => ({
                ...connectForm,
                label: {
                  value: e.target.value,
                  invalid: e.target.value.length > 20,
                },
              }))
            }
            defaultValue={state.label.value ?? undefined}
          />
          <Form.Text className="text-muted">
            Integration name used as label in the mobile app (up to 20
            characters long)
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Label must be 20 characters or less
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Enable Equiem Integration Licence
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Check
            id="gallagherUseIntegrationLicense"
            className="mt-2"
            type="switch"
            onChange={(e) => {
              setState((connectForm) => ({
                ...connectForm,
                useIntegrationLicense: e.target.checked,
              }));
            }}
            defaultChecked={state.useIntegrationLicense}
          />
          {isLicensed === false ? (
            <div>
              <Form.Text className="text-muted">
                <FaInfoCircle
                  color="orange"
                  className="mr-1"
                  style={{ marginTop: "-4px" }}
                />
                There is an issue with your Gallagher licence. You can
                {useIntegrationLicense ? " disable " : " enable "}
                the Equiem Integration Licence to try and resolve this issue. If
                it persists, contact your Gallagher representative for further
                assistance.
              </Form.Text>
            </div>
          ) : null}
        </Col>
      </Form.Group>
      <div className="text-right">
        <Button
          name="gallagherPatch"
          variant="outline-primary"
          onClick={patch}
          disabled={loading || state.label.invalid}
        >
          {loading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Update Gallagher Connection"
          )}
        </Button>
      </div>
    </div>
  );
};
