import React, { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  SiteDetailsQuery,
  useDisconnectOpenpathMutation,
} from "../../../../generated/admin";
import { OpenpathConnectForm } from "./OpenpathConnectForm";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../message/EqMessage";
import dayjs from "dayjs";
import { OpenpathConnectFormEdit } from "./OpenpathConnectFormEdit";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

interface ConnectInfo {
  email: string;
  timestamp: number;
  label: string;
}

export const OpenpathConnect: React.FC<Props> = ({ dest }) => {
  const [connectInfo, setConnectInfo] = useState<ConnectInfo | null>(
    dest.integrations?.openpath.info != null
      ? {
          email: dest.integrations.openpath.info.email,
          timestamp: dest.integrations.openpath.info.connectedTimestamp,
          label: dest.integrations.openpath.info.label ?? undefined,
        }
      : null
  );
  const [displayEditForm, setDisplayEditForm] = useState(false);
  const [mutation, { loading }] = useDisconnectOpenpathMutation({
    variables: { site: dest.uuid },
  });

  const successCb = useCallback(
    (email: string, timestamp: number, label: string) => {
      setConnectInfo({ email, timestamp, label });
    },
    []
  );

  const disconnect = useCallback(async () => {
    const confirm = await EqDangerMessage({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Disconnect",
      title: "Are you sure?",
      html: "Once disconnected, you cannot retrieve this record.",
    });
    if (confirm.value !== true || loading) {
      return;
    }

    try {
      const result = await mutation();
      if (result.data?.openpath?.disconnect == null) {
        throw new Error("Could not disconnect.");
      }

      if (!result.data.openpath.disconnect.success) {
        throw new Error(
          result.data.openpath.disconnect.message ??
            "Unknown error response from server."
        );
      }

      setConnectInfo(null);

      EqMessageSuccess({ text: "Successfully disconnected from Openpath." });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [mutation, loading]);

  return (
    <div className="openpath pt-2">
      <h6>Connection</h6>
      <div className="border p-2 p-md-4 mb-2">
        {connectInfo == null ? (
          <OpenpathConnectForm dest={dest} successCb={successCb} />
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <strong>Label:</strong> {connectInfo.label}
                </div>
                <div>
                  <strong>{connectInfo.email}</strong>
                </div>
                <div>Connected {dayjs(connectInfo.timestamp).fromNow()}</div>
              </div>
              <div>
                <Button
                  name="openpathDisconnect"
                  variant="danger"
                  onClick={disconnect}
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Disconnect"
                  )}
                </Button>
                <Button
                  variant="outline-primary"
                  className="ml-2"
                  onClick={() => setDisplayEditForm(true)}
                  disabled={displayEditForm}
                >
                  Edit
                </Button>
              </div>
            </div>
            {displayEditForm ? (
              <OpenpathConnectFormEdit
                dest={dest}
                successCb={successCb}
                label={connectInfo.label}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
