import React, { useCallback } from "react";
import { capitalize } from "lodash";

import { Formik, FormikErrors, FormikHelpers } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import { OptionType } from "../../../../../model/OptionType";
import { TextFormGroup } from "../../fields/TextFormGroup";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";

import {
  Ac1QrCodeGenerator,
  UpsertQrCodeCredentialSetupInput as FormValues,
  useRemoveAc1CredentialSetupMutation,
  useUpsertQrCodeCredentialSetupMutation,
} from "../../../../../generated/admin";

import { stringNotEmpty } from "../../../../../util/stringNotEmpty";

const options = Object.values(Ac1QrCodeGenerator).map((generator) => ({
  value: generator,
  label: capitalize(generator.replaceAll(/[_-]+/g, " ")),
}));

const handleValidate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!stringNotEmpty(values.passName)) {
    errors.passName = "Required.";
  }

  return errors;
};

type Prop = { values: FormValues };

export const QrCodeSetup: React.FC<Prop> = ({ values }) => {
  const [upsertQrCodeSetup, { loading }] =
    useUpsertQrCodeCredentialSetupMutation({
      refetchQueries: ["Ac1AvailableQrCodeSetups"],
    });
  const [deleteMutation, { loading: deleteInProgress }] =
    useRemoveAc1CredentialSetupMutation();

  const handleSubmit = useCallback(
    async (input: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        const result = await upsertQrCodeSetup({ variables: { input } });

        if (result.errors != null && result.errors.length > 0) {
          console.error(result.errors);
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          text: "QR Code Type updated",
        });
        resetForm({ values });

        return result;
      } catch (e) {
        console.error(e);
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [upsertQrCodeSetup, values]
  );

  const deleteWithConfirmation = useCallback(
    async (name: string) => {
      const confirm = await EqDangerMessage({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        title: "Are you sure?",
        html: `Once "${name}" is deleted, you cannot retrieve it again.`,
      });

      if (confirm.value !== true || deleteInProgress) {
        return;
      }

      try {
        const result = await deleteMutation({
          variables: {
            input: {
              credentialSetupUuid: values.uuid,
              siteUuid: values.siteUuid,
            },
          },
          refetchQueries: ["Ac1AvailableQrCodeSetups"],
        });

        if (result.errors != null) {
          console.error(result.errors);
          if (result.errors.length === 0) {
            throw new Error("Unknown error response from server.");
          }
          throw result.errors[0];
        }

        EqMessageSuccess({ text: `${name} deleted. ` });
      } catch (e: unknown) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [deleteInProgress, deleteMutation, values.uuid, values.siteUuid]
  );

  return (
    <div>
      <Formik
        initialValues={values}
        validate={handleValidate}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(methods) => (
          <CardAccordion
            cardClassName="flex-nowrap"
            title={values.passName}
            collapse={true}
          >
            <div data-testid="qr-generator-picker">
              <Form.Group as={Row} controlId="generator">
                <Form.Label column md="3">
                  QR Code Type
                </Form.Label>
                <Col md="9" lg="6" className="generator-select">
                  <Select<OptionType, boolean>
                    isDisabled={true}
                    classNamePrefix="eq"
                    isSearchable={false}
                    options={options}
                    value={options.find(
                      (option) => option.value === methods.values.generator
                    )}
                    onChange={({ value }: any) => {
                      methods.setFieldValue("generator", value);
                    }}
                    aria-label="generator-select"
                    className="react-select"
                  />
                </Col>
              </Form.Group>
              <TextFormGroup
                title="Pass Name"
                subText="The unique name that the user will see in the mobile app."
                name="passName"
                error={methods.errors.passName}
              />
            </div>
            <div className="text-right">
              <Button
                name="updateCodeSetup"
                variant="outline-primary"
                size="sm"
                className="align-self-center m-2"
                onClick={() => methods.handleSubmit()}
                disabled={loading || !methods.isValid}
              >
                {loading ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Update QR Code Type"
                )}
              </Button>
              <Button
                name="removeCodeSetup"
                variant="outline-danger"
                size="sm"
                className="align-self-center"
                onClick={() => deleteWithConfirmation(methods.values.passName)}
                disabled={deleteInProgress}
              >
                {deleteInProgress ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Delete QR Code Type"
                )}
              </Button>
            </div>
          </CardAccordion>
        )}
      </Formik>
    </div>
  );
};
