import React, { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  type SiteDetailsQuery,
  useDisconnectGallagherMutation,
} from "../../../../generated/admin";
import { GallagherConnectForm } from "./GallagherConnectForm";
import { GallagherConnectFormEdit } from "./GallagherConnectFormEdit";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../message/EqMessage";
import dayjs from "dayjs";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const GallagherConnect: React.FC<Props> = ({ dest }) => {
  const connectInfo = dest.integrations?.gallagher.info;
  const isLicensed = dest.integrations?.gallagher.license?.active;
  const [mutation, { loading }] = useDisconnectGallagherMutation({
    variables: { site: dest.uuid },
    refetchQueries: ["SiteDetails"],
  });
  const [displayEditForm, setDisplayEditForm] = useState(isLicensed === false);

  const disconnect = useCallback(async () => {
    const confirm = await EqDangerMessage({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Disconnect",
      title: "Are you sure?",
      html: "Once disconnected, you cannot retrieve this record.",
    });
    if (confirm.value !== true || loading) {
      return;
    }

    try {
      const result = await mutation();
      if (result.data?.gallagher?.disconnect == null) {
        throw new Error("Could not disconnect.");
      }

      if (!result.data.gallagher.disconnect.success) {
        throw new Error(
          result.data.gallagher.disconnect.message ??
            "Unknown error response from server."
        );
      }

      EqMessageSuccess({ text: "Successfully disconnected from Gallagher." });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [mutation, loading]);

  return (
    <div className="gallagher pt-2">
      <h6>Connection</h6>
      <div className="border p-2 p-md-4 mb-2">
        {connectInfo == null ? (
          <GallagherConnectForm dest={dest} />
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <strong>Label:</strong> {connectInfo.label}
                </div>
                <div>
                  Account connected{" "}
                  {dayjs(connectInfo.connectedTimestamp).fromNow()}. Last
                  connected to API{" "}
                  {dayjs(connectInfo.lastSuccessfulConnection).fromNow()}. API
                  version: {connectInfo.version}
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-baseline">
                <Button
                  name="gallagherDisconnect"
                  variant="danger"
                  onClick={disconnect}
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Disconnect"
                  )}
                </Button>
                <Button
                  variant="outline-primary"
                  className="ml-2"
                  onClick={() => setDisplayEditForm(true)}
                  disabled={displayEditForm}
                >
                  Edit
                </Button>
              </div>
            </div>
            {displayEditForm ? (
              <GallagherConnectFormEdit
                label={connectInfo.label}
                useIntegrationLicense={connectInfo.useIntegrationLicense}
                isLicensed={isLicensed}
                siteUuid={dest.uuid}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
