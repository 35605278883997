import React from "react";

import { useAc1AvailableQrCodeSetupsQuery } from "../../../../../generated/admin";
import { notNullOrUndefined } from "../../../../../util/notNullOrUndefined";

import { QrCodeSetup } from "./QrCodeSetup";

interface Props {
  siteUuid: string;
}

export const QrCodeSetupList: React.FC<Props> = ({ siteUuid }) => {
  // todo move to useAc1Data() hook
  const { data } = useAc1AvailableQrCodeSetupsQuery({
    variables: { siteUuid },
  });
  const qrCodeSetups =
    data?.ac1CredentialSetups
      .map(({ generator, ...setup }) => {
        if (generator == null) {
          return null;
        }

        const uuid: string = setup.uuid;

        return { ...setup, uuid, generator };
      })
      .filter(notNullOrUndefined) ?? [];

  if (qrCodeSetups.length === 0) {
    return null;
  }

  return (
    <div className="container-main">
      <h5>Existing QR Code Types</h5>
      {qrCodeSetups.map(({ uuid, generator, passName }) => (
        <QrCodeSetup
          values={{ uuid, siteUuid, generator, passName }}
          key={uuid}
        />
      ))}
    </div>
  );
};
